import React, { useState } from 'react';
import cls from 'classnames';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { More as MoreIcon } from '@wix/wix-ui-icons-common/on-stage';

import { IGroupRequest } from 'api/group-requests/types';
import { selectGroupRequestApproveStatus } from 'store/selectors';

import { IconButton } from 'wui/IconButton';
import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Stack } from 'wui/Stack';
import { Button } from 'wui/Button';
import { Skeleton } from 'wui/Skeleton';
import { Menu, MenuItem } from 'wui/Menu';
import { Show } from 'wui/Show';

import { Link } from 'common/components/Link';
import { useController } from 'common/context/controller';

import {
  APPROVE_GROUP_CREATION_REQUEST,
  DECLINE_GROUP_CREATION_REQUEST,
} from './dataHooks';
import { DeclineGroupRequestDialog } from './DeclineGroupRequestDialog';

import classes from './styles.scss';

interface IProps {
  request: IGroupRequest;
}

export function PendingGroup(props: IProps) {
  const { request } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { groupRequests$ } = useController();

  const status = useSelector(selectGroupRequestApproveStatus(request.id));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ListItem disablePadding key={request.id}>
      <ListItemText
        title={
          <Link
            state="group"
            params={{ slug: request.group.slug }}
            bi={groupsLivesiteClick({
              screen_name: 'groups_requests',
              button_name: 'view_group',
              group_id: request.id,
            })}
          >
            {request.group.name}
          </Link>
        }
        subtitle={
          <Stack truncate separator={<span>&nbsp;&middot;&nbsp;</span>}>
            <span>
              {t(
                `groups-web.group-list.privacy.${request.group.privacyStatus?.toLowerCase()}`,
              )}
            </span>
            <Link
              state="members.profile"
              params={{ memberId: request.author?.memberId }}
              variant="secondary"
            >
              {t('groups-web.group-list.pending-groups.created-by', {
                authorName: request.author?.name,
              })}
            </Link>
          </Stack>
        }
        subtitleProps={{
          className: cls(classes.info, { [classes.mobile]: isMobile }),
        }}
        titleProps={{
          noWrap: true,
          className: cls(classes.title, { [classes.mobile]: isMobile }),
        }}
      />
      <ListItemAction>
        <Show if={isMobile}>
          <Menu
            element={
              <IconButton icon={<MoreIcon />} onClick={handleMoreIconClick} />
            }
          >
            <MenuItem
              data-hook={DECLINE_GROUP_CREATION_REQUEST}
              content={t('groups-web.group-list.pending-groups.button.decline')}
              onClick={handleDecline}
            />
            <MenuItem
              data-hook={APPROVE_GROUP_CREATION_REQUEST}
              content={t('groups-web.group-list.pending-groups.button.approve')}
              onClick={handleApprove}
            />
          </Menu>
        </Show>

        <Show if={!isMobile}>
          <Button
            data-hook={DECLINE_GROUP_CREATION_REQUEST}
            secondary
            onClick={handleDecline}
          >
            {t('groups-web.group-list.pending-groups.button.decline')}
          </Button>
          <Button
            data-hook={APPROVE_GROUP_CREATION_REQUEST}
            onClick={handleApprove}
            loading={status.loading}
          >
            {t('groups-web.group-list.pending-groups.button.approve')}
          </Button>
        </Show>
      </ListItemAction>

      <DeclineGroupRequestDialog
        request={request}
        isOpen={isOpen}
        onClose={handleDeclineDialogClose}
      />
    </ListItem>
  );

  function handleDeclineDialogClose() {
    setIsOpen(false);
  }

  function handleApprove() {
    groupRequests$.approve(request.id);

    bi.report(
      groupsLivesiteClick({
        screen_name: 'groups_requests',
        button_name: 'approve',
        group_id: request.id,
      }),
    );
  }

  function handleDecline() {
    setIsOpen(true);

    bi.report(
      groupsLivesiteClick({
        screen_name: 'groups_requests',
        button_name: 'decline',
        group_id: request.id,
      }),
    );
  }

  function handleMoreIconClick() {
    bi.report(
      groupsLivesiteClick({
        screen_name: 'groups_requests',
        button_name: 'three_dots',
        group_id: request.id,
      }),
    );
  }
}

PendingGroup.displayName = 'PendingGroup';

export function PendingGroupSkeleton() {
  return (
    <ListItem disablePadding>
      <ListItemText
        title={
          <Skeleton className={classes.groupTitle} variant="text" width="40%" />
        }
        subtitle={
          <Skeleton className={classes.groupInfo} variant="text" width="50%" />
        }
      />
    </ListItem>
  );
}

PendingGroupSkeleton.displayName = 'PendingGroupSkeleton';
