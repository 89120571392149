import React from 'react';
import { useSelector } from 'react-redux';

import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  groupCreatePostClick,
  groupFeedView,
  groupInviteMembersOptionClicked,
} from '@wix/bi-logger-groups/v2';

import { useController } from 'common/context/controller';
import { EmptyState } from 'wui/EmptyState';
import { Wire } from 'wui/Wire';
import { Show } from 'wui/Show';

import { EGroupsNamespace } from 'store/groups/constants';
import { selectGroups, selectIsSiteAdmin } from 'store/selectors';

import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { FeedItemList } from 'Group/Widget/DiscussionPage/GroupFeed/FeedItemList';
import { CreatePost } from 'Group/Widget/DiscussionPage/GroupFeed/CreatePost';
import { InviteMembersDialog } from 'Group/Widget/Header/InviteMembers/InviteMembersDialog';

import settingsParams from 'Groups/settingsParams';

import { InviteMembersCard } from './InviteMembersCard';
import { SelectGroupModal } from './SelectGroupModal';

import { selectShouldShowInviteMembers } from './selectors';

import classes from './CentralFeed.scss';

type DialogsState = {
  createPost: boolean;
  selectGroup: boolean;
  inviteMembers: boolean;
};

export function CentralFeed() {
  const bi = useBi();
  const { t } = useTranslation();
  const { centralFeed$ } = useController();

  const settings = useSettings();

  const [selected, setSelected] = React.useState<string>();
  const actionForGroup = React.useRef<() => void>();

  const [dialogs, _setDialogs] = React.useState<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const prevDialogs = React.useRef<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const showInviteMembersCard = useSelector(selectShouldShowInviteMembers);
  const { groups, meta } = useSelector(selectGroups(EGroupsNamespace.JOINED));

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        container: 'groups',
        roles: isSiteAdmin ? 'admin' : 'member',
      }),
    );
  }, []);

  return (
    <>
      <Wire
        cssVarName="showCreatePost--flex"
        legacyFallback={settings.get(settingsParams.showCreatePost)}
      >
        <CreatePost onClick={handleActionForGroup(openCreatePostDialog)} />
      </Wire>

      <FeedItemList
        showGroupName
        className={classes.list}
        onCreatePost={handleActionForGroup(openCreatePostDialog)}
        onFetch={handleFetchMore}
        slots={{
          empty: (
            <EmptyState
              title={t('groups-web.no-posts.title')}
              subtitle={t('groups-web.no-posts.content')}
            />
          ),
        }}
      />

      <Show if={showInviteMembersCard}>
        <InviteMembersCard
          onClick={handleActionForGroup(openInviteMembersDialog)}
        />
      </Show>

      <SelectGroupModal
        isOpen={dialogs.selectGroup}
        onClose={closeDialogs}
        onSelect={handleGroupSelect}
      />

      <InviteMembersDialog
        isOpen={dialogs.inviteMembers}
        groupId={selected as string}
        onClose={closeDialogs}
      />

      <EditorEventsProvider>
        <FeedItemEditorDialog
          promoteGroup
          groupId={selected}
          onClose={closeDialogs}
          isOpen={dialogs.createPost}
          onCancel={handleBackButtonClick}
          cancelLabel={t('groups-web.discussion.new-post.back')}
        />
      </EditorEventsProvider>
    </>
  );

  function handleFetchMore(cursor?: string) {
    centralFeed$.fetch(cursor);
  }

  function handleActionForGroup(cb: () => void) {
    return () => {
      actionForGroup.current = cb;

      if (meta && meta.total === 1) {
        setSelected(groups[0].id as string);
        cb();
      } else {
        openSelectGroupDialog();
      }
    };
  }

  function openCreatePostDialog() {
    bi.report(
      groupCreatePostClick({
        origin: 'new_layout_groups_sidebar',
      }),
    );

    setDialogs({
      createPost: true,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function openInviteMembersDialog() {
    bi.report(
      groupInviteMembersOptionClicked({
        origin: 'invite_members_sidebar',
        optionType: 'invite',
      }),
    );
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: true,
    });
  }

  function openSelectGroupDialog() {
    setDialogs({
      createPost: false,
      selectGroup: true,
      inviteMembers: false,
    });
  }

  function closeDialogs() {
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function handleGroupSelect(groupId: string) {
    setSelected(groupId);
    actionForGroup?.current?.();
  }

  function handleBackButtonClick() {
    setDialogs(prevDialogs.current);
  }

  function setDialogs(nextDialogs: DialogsState) {
    _setDialogs((dialogs) => {
      prevDialogs.current = dialogs;
      return nextDialogs;
    });
  }
}

CentralFeed.displayName = 'CentralFeed';
